<template>
  <div :class="cardClass" :id="'card_' + room.oid">
      <div class="card-body">
      <div class="media">
        <div class="media-body">
          <div class="d-flex flex-row" style="margin-bottom: 0px;" >
            <div  class="p-0" v-if="frontState !== FRONT_STATE.FALL && frontState !== FRONT_STATE.BED_EXIT && frontState !== FRONT_STATE.HANDLING">
              <img
              class="d-flex mr-3 rounded-circle img-thumbnail thumb-lg "
              :alt="stateIcon"
              :src="stateIcon"
              :data-state="frontState"
              id="kiosk_sensorstatusicon"
              />
            </div>
            
            <div class="p-0">
              <h5 :style="(frontState === FRONT_STATE.FALL || frontState === FRONT_STATE.BED_EXIT || frontState === FRONT_STATE.HANDLING) ? 'color: white;font-weight: bolder;' : '' " id='kiosk_roomname'>
                {{ 
                (multipleRoomsetVisible ? roomset.label + ' / ' : '') + (room.label.startsWith('_') ? room.label.substring(1) : room.label)
                }}
              </h5>
              <h5 :style="'color: white;font-weight: bolder;'+btnEyeStyle" id='kiosk_alerttype'>{{AlertText}}
                    <a
                      role="button"
                      tabindex="0"
                      :class="'tooltips m-auto ' + closeFollowUpBtnClass"
                      data-tip="true"
                      data-for="Close follow up"
                      currentitem="false"
                      @click="watch"
                      v-if="isCloseFollowUpEnabled && hasCloseFollowUpLicense"
                      >
                      <svg class="docblink" v-if="docBtnEye">      
                          <polygon fill=red stroke-width=0   :points="docBtnPoints" />
                      </svg>
                      <font-awesome-icon icon="eye" style="margin-left: 2rem; font-size: 3rem;" class="icon alt" :id="'roombtn_watch_' + room.oid" :ref="'roombtn_watch_' + room.oid"/>
                    </a>
              </h5>
              <div id="kiosk_sensoricons" class="d-flex flex-row align-content-end" style="bottom: 0;">
                <div v-if="frontState !== FRONT_STATE.SETUP" :style="btnStyle"  class="p-0" >
                  <div class="isaroombtn">
                    <a
                      role="button"
                      tabindex="0"
                      class="tooltips m-auto"
                      data-tip="true"
                      data-for="Power"
                      currentitem="false"
                      @click="powerBtn"                      
                    >
                      <svg class="docblink" v-if="docBtnPower">      
                          <polygon fill=red stroke-width=0   :points="docBtnPoints" />
                      </svg>
                      <font-awesome-icon :class="powerBtnClass" icon="power-off" :id="'roombtn_poweroff_' + room.oid" :ref="'roombtn_poweroff_' + room.oid"/>
                    </a>
                  </div>
                </div>
                <div  v-if="frontState !== FRONT_STATE.SETUP" :style="btnStyle"  class="p-0" >
                  <div class="isaroombtn">
                    <a
                      role="button"
                      tabindex="0"
                      class="tooltips m-auto"
                      data-tip="true"
                      data-for="Bed exit detection"
                      currentitem="false" 
                      @click="editBedExitSettings"                     
                      >
                      <!-- v-if="frontState !== FRONT_STATE.OFFLINE" -->
                      <svg class="docblink" v-if="docBtnBed">      
                          <polygon fill=red stroke-width=0   :points="docBtnPoints" />
                      </svg>
                      <font-awesome-icon :class="bedExitBtnClass" icon="procedures" :id="'roombtn_bedsettings_' + room.oid" :ref="'roombtn_bedsettings_' + room.oid"/>
                    </a>
                  </div>
                </div>
                <div v-if="frontState !== FRONT_STATE.SETUP" :style="btnStyle"  class="p-0" >
                  <div class="isaroombtn">
                    <a
                      role="button"
                      tabindex="0"
                      :class="'tooltips m-auto ' + closeFollowUpBtnClass"
                      data-tip="true"
                      data-for="Close follow up"
                      currentitem="false"
                      @click="watch"
                      v-if="isCloseFollowUpEnabled && hasCloseFollowUpLicense"
                      :id="'roombtn_followup_' + room.oid"
                    >
                      <!-- v-if="isCloseFollowUpEnabled && hasCloseFollowUpLicense && frontState !== FRONT_STATE.OFFLINE" -->
                      <svg class="docblink" v-if="docBtnEye">      
                          <polygon fill=red stroke-width=0   :points="docBtnPoints" />
                      </svg>
                      <font-awesome-icon icon="eye" class="icon alt" :id="'roombtn_closefollowup_' + room.oid" :ref="'roombtn_closefollowup_' + room.oid"/>
                    </a>
                  </div>
                </div>

                <div :style="btnHelpStyle" class="p-0" >
                  <div class="isaroombtn">
                    <a
                      role="button"
                      tabindex="0"
                      class="tooltips m-auto"
                      data-tip="true"
                      data-for="Power"
                      currentitem="false"   
                      @click="askForHelp"                   
                      >
                      <svg class="docblink" v-if="docBtnPower">      
                          <polygon fill=red stroke-width=0   :points="docBtnPoints" />
                      </svg>
                      <font-awesome-icon icon="question-circle" :id="'roombtn_poweron_' + room.oid" :ref="'roombtn_poweron_' + room.oid"/>
                    </a>
                  </div>
                </div>

                </div>
              </div>
              
            </div>
            <div  v-if="isAlertState" class="ml-auto p-0 align-content-end" >              
              <div v-if="frontState === FRONT_STATE.FALL || frontState === FRONT_STATE.BED_EXIT || frontState === FRONT_STATE.HANDLING" class="float-right align-bottom mb-auto mt-auto isablink">
                <svg class="docblink" v-if="docBtnAction">      
                          <polygon fill=red stroke-width=0   :points="docActionBtnPoints" />
                </svg>
                <img            
                  role="button"
                  tabIndex="0"                  
                  class="rounded mr-2 img-thumbnail "                  
                  :src="handlingBtnSrc"
                  data-holder-rendered="true"
                  @click="handlingBtnAction"
                  :id="'roombtn_useraction_' + room.oid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AuthService } from "@/services/auth.service";
import Moment from 'moment-timezone';
import { IMGService } from '@/services/img.service'
import Arrow from './Arrow'  ;

export default {
  name: "ISARoom",  
  created() {
    this.FRONT_STATE = {
      NO_ADMISSION: -1,
      OFFLINE: 0,
      ONLINE: 1,
      FALL: 2,
      HANDLING: 3,
      WALKING: 4,
      AGITATED_IN_BED: 5,
      SLEEP: 6,
      AUTO_HANDLING: 7,
      TIMEOUT: 8,
      BED_EXIT: 9,
      SETUP: 10,
    };
    this.STABLE_STATE = {
      WALKING: 10,
      LAYING_IN_BED: 30,
      AGITATED_IN_BED: 31,
      OFFLINE: 50,
    };
  },

  props: {
    room: { type: Object },
    roomset: { type: Object },
    onBtnHelp: { type: Function},
    onBtnPower: { type: Function},
    onBtnEye: { type: Function},
    onBtnBed: { type: Function},
    onBtnAction: { type: Function },
    docBtnHelp: { type: Boolean},
    docBtnPower: { type: Boolean},
    docBtnEye: { type: Boolean},
    docBtnBed: { type: Boolean},
    docBtnAction: { type: Boolean },
  },
  data() {
    return {
      label() {
        return this.room.label;
      },
      docBtnPoints: '3,45 23,45 13,35',
      docActionBtnPoints: '25,90 45,90 35,80',
     
    };
  },
  computed: {
    multipleRoomsetVisible() {
      return this.$store.state.fleet.multipleRoomsetVisible;
    },
    frontState() {
      if (this.room.label[0] === '_') return this.FRONT_STATE.SETUP;
      if (typeof this.room.sensor.isOnline === 'undefined' && this.room.label[0] !== '_') return this.FRONT_STATE.TIMEOUT;
     // if (this.room.sensor.isOnline === 0) return FRONT_STATE.TIMEOUT;
      if (this.room.sensor.isOnline === -1 && this.room.label[0] !== '_') return this.FRONT_STATE.TIMEOUT;

      if (!this.room.sensor.settings.enabled) return this.FRONT_STATE.OFFLINE;
      
      if(!this.room.admissionList || !this.room.admissionList.length || !this.room.admissionList[this.room.admissionList.length - 1])
        return this.FRONT_STATE.NO_ADMISSION;

      let admission = this.room.admissionList[this.room.admissionList.length - 1];

      if (admission.stableState.code === this.STABLE_STATE.OFFLINE) return this.FRONT_STATE.OFFLINE;

      let alert = admission.alert && admission.alert.moderation && admission.alert.moderation.createdAt && admission.alert.moderation.trueAlert === true ? admission.alert : null;

      //if(this.room.label === 'room 0')
      //debugger
            
      if (!alert) { // Not alert
        /* if (admission.stableState.code === this.STABLE_STATE.WALKING) return this.FRONT_STATE.WALKING;
        if (admission.stableState.code === this.STABLE_STATE.AGITATED_IN_BED) {
          return this.FRONT_STATE.AGITATED_IN_BED;
        }
        if (admission.stableState.code === this.STABLE_STATE.LAYING_IN_BED) return this.FRONT_STATE.SLEEP;
        */
      } else {        
        if (alert && !alert.handling.createdAt && !alert.autoHandling.createdAt && alert.alertType == 5) return this.FRONT_STATE.FALL;
        if (alert && !alert.handling.createdAt && !alert.autoHandling.createdAt && alert.alertType == 4) return this.FRONT_STATE.BED_EXIT;            
        if (alert && !alert.handling.createdAt && !alert.autoHandling.createdAt && alert.alertType == 3) return this.FRONT_STATE.ONLINE;
      }  
      if (admission.expectingHandled) return this.FRONT_STATE.HANDLING;    
      if (this.room.sensor.isOnline === 0) return this.FRONT_STATE.TIMEOUT;
      return this.FRONT_STATE.ONLINE;
    },
    alert() {
      if(this.room.admissionList && this.room.admissionList.length && this.room.admissionList[this.room.admissionList.length - 1])
      {
        let adm = this.room.admissionList[this.room.admissionList.length - 1];  
        if(adm.alert) {
          console.log(adm.alert)
        }      
        return adm.alert;
      }
      return null;
    },
    cardClass() {
      return "card m-b-20" 
        + (this.frontState === this.FRONT_STATE.FALL ? " bg-danger"  : "")
        + (this.frontState === this.FRONT_STATE.BED_EXIT ? " bg-warning"  : "")      
        + (this.frontState === this.FRONT_STATE.HANDLING || this.frontState === this.FRONT_STATE.AUTO_HANDLING ? ' bg-primary' : '');
    },
    rowStyle() {
      return "bottom: 0, width: 100%; position: " + this.bigButton
        ? "absolute"
        : "relative";
    },
    handlingBtnStyle() {
      return `height: 88px;width: 88px;boxShadow: ${this.$store.state.fleet.cronSecond ? '7px 7px 5px -4px rgba(0,0,0,0.5)' : ''};transform: ${this.$store.state.fleet.cronSecond ? 'translate(-1px, -1px)' : 'translate(0px, 0px)'}`
    },
    handlingBtnSrc(){
      if(this.frontState === this.FRONT_STATE.HANDLING)
         return IMGService.takeCare;
      else
        if (this.frontState === this.FRONT_STATE.BED_EXIT)
         return IMGService.move;
        else 
          return IMGService.fall;
    },    
    bigButton() {
      return true;
    },
    sensorOnline() {
      if(this.frontState === this.FRONT_STATE.TIMEOUT) return false;
      else return true
    },
    sensorEnabled() {
      if(this.frontState === this.FRONT_STATE.OFFLINE) return false;
      else return true
    },

    stateIcon() {

      switch (this.frontState) {
      case this.FRONT_STATE.NO_ADMISSION:
        return (IMGService.user_closed);      
      case this.FRONT_STATE.OFFLINE:
        return (IMGService.sensorOff);
      case this.FRONT_STATE.ONLINE:
        return (IMGService.sensorOn);
      case this.FRONT_STATE.FALL:
        return (IMGService.fall);
      case this.FRONT_STATE.BED_EXIT:
        return (IMGService.move);
      case this.FRONT_STATE.HANDLING:
        return (IMGService.takeCare)
      case this.FRONT_STATE.WALKING:
        return (IMGService.move);
      case this.FRONT_STATE.AGITATED_IN_BED:
        return (IMGService.wakeUp);
      case this.FRONT_STATE.SLEEP:
        return (IMGService.sleep);
      case this.FRONT_STATE.AUTO_HANDLING:
        return (IMGService.autoTakeCare);
      case this.FRONT_STATE.TIMEOUT:
        return (IMGService.timeout);
      case this.FRONT_STATE.SETUP:
        return (IMGService.setup);
      default:
        return (IMGService.sensorOff);
    }             
    },

    hasAdmission() {
      return (
        this.room.admissionList &&
        this.room.admissionList.length &&
        this.room.admissionList[this.room.admissionList.length - 1].outDate ==
          null
      );
    },

    powerBtnClass() {
      if(this.room.sensor.settings.enabled)
        return "WATCHEnabled";
      else
        return "WATCHDisabled";
    },
    bedExitBtnClass() {
      if(this.$store.state.fleet.cronMinute) {
        // tick
        // cron Minute changes every minute, so that recomputes state for the button
       // console.log('tick cronMinute');
      }

      if(this.room.sensor.settings.enableBedExitDetection)
      {
        if(this.room.sensor.settings.bedExitTimeRanges && this.room.sensor.settings.bedExitTimeRanges)  {
          const now = Moment.utc();
          const nowMinutes = now.hours() * 60 + now.minutes();          
          let isWithinTimeRanges = false;
          let utcOffset = null; 
          if (  this.roomset.timezone === null || this.roomset.timezone === "" ) {
              utcOffset = Moment.tz.zone("Europe/Brussels").utcOffset(Moment.utc());
          } else {
              utcOffset = Moment.tz.zone(this.roomset.timezone).utcOffset(Moment.utc());
          }
          for (const bedExitTimeRange of this.room.sensor.settings.bedExitTimeRanges) {
            const startTimeInMinutes = bedExitTimeRange.startHourLocalTime * 60 + bedExitTimeRange.startMinuteLocalTime + utcOffset;
            const endTimeInMinutes = bedExitTimeRange.endHourLocalTime * 60 + bedExitTimeRange.endMinuteLocalTime + utcOffset;
            if (startTimeInMinutes === endTimeInMinutes) {
              // then isWithinTimeRanges is always true
              isWithinTimeRanges = true;
            } else if (startTimeInMinutes < endTimeInMinutes) {
              if (startTimeInMinutes <= nowMinutes && nowMinutes <= endTimeInMinutes) {
                isWithinTimeRanges = true;
              }
            } else if (nowMinutes <= endTimeInMinutes || startTimeInMinutes <= nowMinutes) { // startTimeInMinutes > endTimeInMinutes
              isWithinTimeRanges = true;
            }
          }
          if(isWithinTimeRanges) {
            return "BEActive";
          } else {
            return "BEInactive";
          }
        }
      

      } else {
        return "BEdisabled";
      }
      return "";
    },
    isCloseFollowUpEnabled() {
        
        return this.roomset.licensing && this.roomset.featureCloseFollowUpEnabled;
        
    },
    hasCloseFollowUpLicense() {

      return this.roomset.licensing && this.roomset.licensing.closeFollowUp;

    },
    isAlertState() {
      return this.frontState === this.FRONT_STATE.FALL || this.frontState === this.FRONT_STATE.BED_EXIT || this.frontState === this.FRONT_STATE.HANDLING            
    },
    btnStyle() {
      // if(!this.sensorOnline)
      //   return "display: none";
      if(!this.isAlertState)
        return "display: block";      
      else
        return "display: none";
    },
    btnHelpStyle() {
      if(!this.isAlertState && this.room.label[0] === '_')
        return "visibility: visible";
      else if(this.sensorOnline || this.isAlertState)
        return "display: none";
      else 
        return "visibility: visible";
    },
    btnEyeStyle() {
      if(this.isAlertState)
        return "display: block";
      else 
        return "display: none";
    },
    btnEnabled() {
      if(this.isAlertState)
        return "visibility: visible";
      else 
        return "visibility: hidden";
    },    
    closeFollowUpBtnClass() {
      if(this.room.sensor.settings.enableFollowUp)
        return 'WATCHEnabled'
      else
        return 'WATCHDisabled'
    },
    AlertText() {
      if(this.frontState === this.FRONT_STATE.FALL)
        return this.$t('Fall Alert')
      else if (this.frontState === this.FRONT_STATE.BED_EXIT)
        return this.$t('Bed Exit Alert')
      else if(this.frontState === this.FRONT_STATE.HANDLING) 
        return this.$t('Close Incident')
      return '';
    }
  },
  components: {    
    Arrow
  },
  methods: {
    async watch() {
      if(this.onBtnEye)
        return this.onBtnEye(this.roomset, this.room);
      const action = {
        action: "fleet/watchRoom",
        args: {
          watching: false,
          label: this.room.label,
          oid: this.room.oid,
          room: this.room,
          enable: true,

        }        
      };
      let room = this.room
      let dispatch = this.$store.dispatch
      if (AuthService.checkLogin()) {
        // now check the room is accessible
        if(this.room.sensor.settings.enableFollowUp)
        {
          console.log('watch room')
          this.$store.dispatch("fleet/watchRoom", action);
        } else 
        {
          this.$dialog
          .confirm(this.$t('Do you want to activate close follow up on this room?'), { okText: this.$t('Yes'), cancelText: this.$t('No')} )
          .then(async (dialog) => {
            console.log('dispatch enable close follow up')
            await dispatch("fleet/enableCloseFollowUp", room.oid);
            console.log('now open watch room')
            this.$store.dispatch("fleet/watchRoom", action);

          })
          .catch(function() {
            
          });
        }
        
      } else {
        await this.$store.dispatch("user/needsLogin", action);
        console.log('start modal service')
        
        // console.log("MODALRESPONSE!! " + modalResponse)
      }
    },
    async powerBtn(){
      if(this.onBtnPower)
        return this.onBtnPower(this.roomset, this.room);        
      if(this.isAlertState) return;

      
      let room = this.room
      let dispatch = this.$store.dispatch

      const action = {
        action: this.room.sensor.settings.enabled ? "fleet/disableSensor" : "fleet/enableSensor",
        args: room,        
      };
      
      if (AuthService.checkLogin()) {
        // now check the room is accessible
        if(!this.room.sensor.settings.enabled)
        {
          this.$dialog
            .confirm(this.$t('Do you want to enable sensor?'), { okText: this.$t('Yes'), cancelText: this.$t('No')})
            .then(function(dialog) {
              dispatch("fleet/enableSensor", room);
            })
            .catch(function() {
            });
        } else {
          this.$dialog
            .confirm(this.$t('Do you want to disable the sensor?'), { okText: this.$t('Yes'), cancelText: this.$t('No')})
            .then(function(dialog) {
              dispatch("fleet/disableSensor", room);
            })
            .catch(function() {
            });
        }    
      } else {
        await this.$store.dispatch("user/needsLogin", action);
        console.log('start modal service')
      }
    },
    async editBedExitSettings() {
      if(this.onBtnBed)
        return this.onBtnBed(this.roomset, this.room);
      if(this.isAlertState) return;      

      let room = this.room
      let dispatch = this.$store.dispatch

      const action = {
        action: "fleet/editBedSettings",
        args: room,        
      };

      if (AuthService.checkLogin()) {

        this.$store.commit('user/SHOW_BEDEXITSETTINGS',this.room)
      }
      else {
        await this.$store.dispatch("user/needsLogin", action);
        console.log('start modal service')
      }
    },

    askForHelp() {
      if(this.onBtnHelp)
        return this.onBtnHelp(this.roomset, this.room);
      if(this.isAlertState) return;
      if(this.room.label[0] === '_') this.$store.commit("user/NEEDS_HELP", "ISASensorSetup");
      else this.$store.commit("user/NEEDS_HELP", "ISASensorOffline");
    },
    handlingBtnAction() {
      if(this.onBtnAction)
        return this.onBtnAction(this.roomset, this.room);
      let room = this.room
      let dispatch = this.$store.dispatch
       if(this.frontState === this.FRONT_STATE.HANDLING)
       {
         this.$dialog
          .confirm(this.$t('Please confirm to continue'), { okText: this.$t('Yes'), cancelText: this.$t('No')})
          .then(function(dialog) {
            dispatch("fleet/endAlert", room);
          })
          .catch(function() {
            
          });
       }
          
      else
        this.$dialog
          .confirm(this.$t('Do you take the alert in charge?'), { okText: this.$t('Yes'), cancelText: this.$t('No')})
          .then(function(dialog) {
            dispatch("fleet/takeInChargeAlert", room);
          })
          .catch(function() {
            
          });
         
    },
    
  }
};
</script>

<style lang="scss" scoped>
.dg-content {
  text-align: left !important;
  font-size: 1.5rem;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>

<style lang="scss" scoped>
  .thumb-lg {
    
    max-height: 100%;
    max-height: 90px;
  }

  .a {
    text-decoration: none;
    outline:none;
  }

  .isaroombtn {
    margin: 0.5rem;
    cursor: pointer;
   // border-right: 1px solid rgba(0, 0, 0, 0.05);
    // display: flex;
    // min-width: 50px;
    max-height: 50px;
  }
    .isaroombtn a {
    font-size: 1.5rem; 
    // font-size: 1vw; 
    margin-left: 10px;
    color: rgb(47, 44, 44);
    text-decoration: none;
    outline:none;
  }

  
  .card-body {
    padding: 0.8rem;
    max-height: 115px;
    min-height: 115px;
  }


  .WATCHEnabled {
    color: black !important;
    text-decoration: none;
    outline:none;
  }
  
  .WATCHDisabled {
      color: rgb(196, 183, 183) !important;
  }
  
  .BEActive {
    color: #88cabe !important;
  }
  
  .BEInactive {
    color: rgb(80, 116, 110)!important;
  }
  
  .BEdisabled {
    color: rgb(196, 183, 183)!important;
  }


  @keyframes isablinkanim {
    50% {
      boxShadow: 7px 7px 5px -4px rgba(0,0,0,0.5);
      transform: translate(-1px, -1px);
    }
  }
  @-webkit-keyframes isablinkanim {
    50% {
      boxShadow: none;
      transform: translate(0px, 0px);
    }
  }
  .isablink {
    height: 88px;
    width: 88px;    
    animation: isablinkanim 1s step-start 0s infinite;
    -webkit-animation: isablinkanim 1s step-start 0s infinite;
    top: 10px;
    right: 10px;
    position: absolute
  }

  @keyframes docblinkanim {
    50% {
      boxShadow: 7px 7px 5px -4px rgba(0,0,0,0.5);
      transform: translate(-0px, -5px);
    }
  }
  @-webkit-keyframes docblinkanim {
    50% {
      boxShadow: none;
      transform: translate(0px, 0px); 
    }
  }
  .docblink {    
    animation: docblinkanim 1s step-start 0s infinite;
    -webkit-animation: docblinkanim 1s step-start 0s infinite;    
    position: absolute
  }

  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    padding-right: 1px;
    padding-left: 1px;
  }
</style>

